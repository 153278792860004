




















import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    IconAt: () => import('@/components/UI/icons/IconAt.vue'),
  },
})
export default class MeetingCardMessageCounter extends Vue {
  @Prop({
    required: false,
    type: Number,
  }) numUnread!: number

  @Prop({
    required: false,
    type: Number,
  }) numUnreadNotices!: number

  private get isHaveNotices (): boolean {
    return !!this.numUnreadNotices
  }
}
